import React from 'react';

import Layout from 'components/Layout';
import AboutUs from 'components/AboutUs';
import Offer from 'components/Offer';
import Contact from 'components/Contact';

const title = 'Motoroma | naprawa motocykli, quad, wulkanizacja - Jarocin';
const description =
  'Mechanika motoryzacyjna, naprawa motocykli i quadów, wulkanizacja, sprzedaż części zamiennych. Jarocin, Pleszew, Kalisz, Krotoszyn, Ostrów Wielkopolski';
const Home = () => (
  <Layout
    title="Serwis motocyklowy"
    slug="/"
    metaTitle={title}
    metaDescription={description}
  >
    <AboutUs />
    <Offer />
    <Contact />
  </Layout>
);

export default Home;
