import React from 'react';

import Section from 'components/Section';
import OfferRange from 'components/OfferRange';

const Offer = () => (
  <Section header="Oferta" darkBackground path="/offer">
    <OfferRange />
  </Section>
);

export default Offer;
