import React from 'react';

import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import BREAKPOINTS from 'css/breakpoints';
import Section from 'components/Section';
import P from 'components/P';

const StyledP = styled(P)`
  margin-bottom: 60px;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    margin-bottom: 100px;
  }
`;

const AboutUs: React.FC = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query AboutUsComponentQuery {
        markdownRemark(fileAbsolutePath: { regex: "/about_us_segment/" }) {
          frontmatter {
            about_us_segment_button_link
            about_us_segment_copy
            about_us_segment_header
          }
        }
      }
    `
  );

  const {
    about_us_segment_header,
    about_us_segment_copy,
    about_us_segment_button_link,
  } = markdownRemark.frontmatter;

  return (
    <Section
      header={about_us_segment_header}
      path={about_us_segment_button_link}
    >
      <StyledP textAlign="center">{about_us_segment_copy}</StyledP>
    </Section>
  );
};

export default AboutUs;
